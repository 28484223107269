<template>
    <div class="col-lg-4 order-lg-2 order-md-1 order-sm-1 order-1 mb-0 mb-md-5">
        <affix
            relative-element-selector=".accordion-wrapper"
            :enabled="affixEnabled"
            :offset="{ top: topOffset, bottom: 0 }"
            :style="{ width: affixWidth }"
            @affixtop="affixed = false"
            @affix="affixed = true"
        >
            <div ref="pricingCard" class="container-fluid px-0">
                <div class="pricing-card-wrapper pl-0 pl-lg-3">
                    <div class="pricing-card">
                        <!--* Mobile Card Header only displays on mobile *-->
                        <div
                            id="headingMobileVehicleTitle"
                            class="card-header d-flex d-md-none"
                            role="tab"
                            data-toggle="collapse"
                            href="#collapseMobileVehicleTitle"
                            aria-expanded="true"
                        >
                            <div
                                class="d-flex flex-column d-md-none justify-content-between w-100 border-bottom vehicle-title"
                            >
                                <span class="year-and-make mb-0">
                                    {{ yearAndMake }}
                                </span>
                                <div
                                    class="d-flex justify-content-between w-100"
                                >
                                    <span class="model-and-trim mb-0">
                                        {{ vehicle.model }} {{ vehicle.trim }}
                                    </span>
                                    <i
                                        aria-hidden="true"
                                        class="ion-chevron-up"
                                    />
                                </div>
                            </div>
                        </div>
                        <!--* End of Mobile Card Header *-->

                        <div
                            id="collapseMobileVehicleTitle"
                            class="collapse show"
                            aria-labelledby="headingMobileVehicleTitle"
                            data-target="#collapseMobileVehicleTitle"
                        >
                            <div class="container p-0">
                                <transition name="fade">
                                    <div
                                        v-if="affixed"
                                        class="card-vehicle-img p-0 border-0"
                                    >
                                        <b-img
                                            v-if="front34"
                                            :src="front34"
                                            :alt="vehicleName"
                                            fluid
                                            @error="imageUnavailable($event)"
                                        />
                                        <b-img
                                            v-else-if="splashImage"
                                            :src="splashImage"
                                            :alt="vehicleName"
                                            fluid
                                            @error="imageUnavailable($event)"
                                        />
                                        <div
                                            v-else
                                            class="body-style-icon d-flex justify-content-center py-5"
                                        >
                                            <body-style-icon
                                                :id="vehicle.bodyStyle"
                                            />
                                        </div>

                                        <div
                                            class="desktop-vehicle-title d-none d-md-flex flex-column align-items-center px-3"
                                        >
                                            <span class="ymm font-weight-bold text-center">
                                                {{ yearAndMake }}
                                                {{ vehicle.model }}
                                            </span>
                                            <span>{{ vehicle.trim }}</span>
                                        </div>
                                    </div>
                                </transition>
                            </div>

                            <div class="container-fluid">
                                <div class="row pricing-card-tabs my-2">
                                    <ul
                                        class="col nav nav-tabs m-auto nav-pills"
                                        role="tablist"
                                    >
                                        <li class="nav-item">
                                            <a
                                                class="nav-link active"
                                                data-toggle="tab"
                                                data-target="#price"
                                            >
                                                {{
                                                    $t(
                                                        "component.pricingCard.priceTab"
                                                    )
                                                }}
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a
                                                class="nav-link"
                                                data-toggle="tab"
                                                data-target="#payment"
                                            >
                                                {{
                                                    $t(
                                                        "component.pricingCard.paymentTab"
                                                    )
                                                }}
                                            </a>
                                        </li>
                                        <li
                                            v-if="isCertifiedDealer"
                                            class="nav-item"
                                        >
                                            <a
                                                class="nav-link"
                                                data-toggle="tab"
                                                data-target="#details"
                                            >
                                                {{
                                                    $t(
                                                        "component.pricingCard.detailsTab"
                                                    )
                                                }}
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                <div class="tab-content row">
                                    <price-tab />

                                    <payment-tab
                                        :can-lease="canLease"
                                        :can-finance="canFinance"
                                    />

                                    <details-tab v-if="isCertifiedDealer" />
                                </div>
                            </div>

                            <div
                                v-if="showRestrictedMake && certificateId"
                                class="restricted-make-help-link pb-3"
                            >
                                <i
                                    aria-hidden="true"
                                    class="ion ion-android-mail"
                                />
                                <b-link
                                    @click="$refs.restrictedMakeModal.show()"
                                >
                                    {{ $t("component.pricingCard.noEmail") }}
                                </b-link>
                            </div>
                        </div>

                        <restricted-make-email-modal
                            v-if="showRestrictedMake && certificateId"
                            ref="restrictedMakeModal"
                            :certificate-id="certificateId"
                        />
                        <lifetime-warranty-modal ref="lifetimeWarrantyModal" />
                        <get-insurance-modal />
                        <contact-dealer />
                        <schedule-test-drive />

                        <!-------- Scrolls with the card below it -------->

                        <div
                            v-if="!isUserSignedIn || !dealerLinkExists"
                            class="d-flex justify-content-center my-2 contact-us-block"
                        >
                            <div
                                class="d-none d-md-flex flex-column text-center contact-us"
                            >
                                <span class="mb-1 title">
                                    {{
                                        $t(
                                            "component.pricingCard.contactUs.questions"
                                        )
                                    }}
                                </span>
                                <span class="mb-1 sub-title">
                                    {{
                                        $t(
                                            "component.pricingCard.contactUs.speakToAutoAdvisor"
                                        )
                                    }}
                                </span>
                                <div>
                                    <b-link :href="'tel:' + phoneNumber">
                                        {{ phoneNumber }}
                                    </b-link>
                                </div>
                            </div>
                        </div>

                        <div v-else class="d-flex my-2 cta-block">
                            <div
                                class="container-fluid d-flex align-items-center cta"
                            >
                                <div class="d-flex flex-column w-100">
                                    <div
                                        class="d-flex mb-2 justify-content-between"
                                    >
                                        <b-button
                                            class="btn__left btn-gray"
                                            @click="openContactDealer"
                                        >
                                            {{
                                                $t(
                                                    "component.pricingCard.contactDealer"
                                                )
                                            }}
                                        </b-button>

                                        <b-button
                                            class="btn__right btn-gray"
                                            @click="gotoTradeIn"
                                        >
                                            {{
                                                $t(
                                                    "component.pricingCard.valueTradeIn"
                                                )
                                            }}
                                        </b-button>
                                    </div>

                                    <div class="d-flex mb-4 justify-content-between">
                                        <b-button
                                            v-if="financingAvailable"
                                            class="btn__left btn-gray"
                                            @click="openFinance()"
                                        >
                                            {{
                                                $t(
                                                    "component.pricingCard.applyForFinancing"
                                                )
                                            }}
                                        </b-button>

                                        <b-button
                                            v-else-if="
                                                !financingAvailable &&
                                                    supportsTestDrive
                                            "
                                            class="btn__left btn-gray"
                                            @click="scheduleTestDrive"
                                        >
                                            {{
                                                $t(
                                                    "component.pricingCard.scheduleTestDrive"
                                                )
                                            }}
                                        </b-button>

                                        <b-button
                                            v-else
                                            class="btn__left btn-gray"
                                            @click="onViewInventoryClick"
                                        >
                                            {{
                                                $t(
                                                    "component.pricingCard.viewInventory"
                                                )
                                            }}
                                        </b-button>

                                        <b-button
                                            class="btn__right btn-gray"
                                            @click="openInsurance()"
                                        >
                                            {{
                                                $t(
                                                    "component.pricingCard.getInsuranceQuotes"
                                                )
                                            }}
                                        </b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </affix>
    </div>
</template>
<script>
import { dispatch, get } from "vuex-pathify";
import _ from "lodash";
import EventBus from "@/event-bus";
import LifetimeWarrantyModal from "Components/LifetimeWarrantyModal";
import ContactDealer from "Components/ContactDealer";
import ScheduleTestDrive from "Components/ScheduleTestDrive";
import GetInsuranceModal from "Modules/InsuranceModal";
import PriceTab from "./components/PriceTab";
import PaymentTab from "./components/PaymentTab";
import DetailsTab from "./components/DetailsTab";
import RestrictedMakeEmailModal from "./components/RestrictedMakeEmailModal";
import BodyStyleIcon from "Modules/ShopBy/BodyStyle/components/BodyStyleIcon/index";
import SignInForm from "Modules/Auth/SignIn/components/SignInForm";
import imageUnavailable from "@/mixins/imageUnavailable";

export default {
    name: "PricingCard",

    components: {
        BodyStyleIcon,
        DetailsTab,
        PaymentTab,
        PriceTab,
        RestrictedMakeEmailModal,
        GetInsuranceModal,
        ScheduleTestDrive,
        LifetimeWarrantyModal,
        ContactDealer,
        SignInForm
    },

    mixins: [imageUnavailable],

    data() {
        return {
            affixEnabled: false,
            affixed: false,
            affixWidth: 370
        };
    },

    computed: {
        canFinance: get("vehicleDetails/vehicle@financingAvailable"),
        canLease: get("vehicleDetails/vehicle@leaseAvailable"),
        pricing: get("vehicleDetails/pricing"),
        restrictedMake: get("vehicleDetails/vehicle@restrictedMake"),
        vehicle: get("vehicleDetails/vehicle"),
        inventoryVehicle: get("vehicleDetails/inventoryVehicle"),
        dealerName: get("vehicleDetails/dealer@name"),
        vin: get("vehicleDetails/vehicle@vin"),
        zipCode: get("location/selectedLocation@zipCode"),
        certificate: get("vehicleDetails/certificate"),
        dealer: get("vehicleDetails/dealer"),
        isUserSignedIn: get("user/isSignedIn"),
        isFinancingAvailable: get("vehicleDetails/isFinancingAvailable"),
        phoneNumber: get("campaign/phoneNumberFormatted"),
        carouselImageUrls: get("vehicleDetails/media@carouselImageUrls"),
        front34: get("vehicleDetails/media@front34/small"),
        splashImage: get("vehicleDetails/media@splashImage"),
        dealerLinkExists: get("dealerLink/active"),
        canUnlockPricing: get("dealerLink/canUnlockPricing"),
        supplierPricingAvailable: get(
            "vehicleDetails/pricing@supplierPricingAvailable"
        ),
        topMenuHeight: get("headerTransitionStore/cutOffheight"),

        topOffset() {
            const pricingCardWwrapperTop = 175; // top position for pricing-card-wrapper
            const paddingValue = 5; // in pixels between top menu and pricing card
            const topMenuOffset = this.topMenuHeight;

            return pricingCardWwrapperTop + paddingValue + topMenuOffset;
        },
        supportsTestDrive() {
            return _.get(this.dealer, "supportsTestDrive", true);
        },

        showRestrictedMake() {
            return (
                this.restrictedMake &&
                this.canUnlockPricing &&
                this.certificate.sentPriceByEmail
            );
        },
        certificateId() {
            return _.get(this.certificate, "id", null);
        },
        savings() {
            const salePrice = _.get(this.pricing, "salePrice.value");
            const msrp = _.get(this.pricing, "msrp");

            return msrp - salePrice;
        },
        financingAvailable() {
            return this.canFinance && this.isFinancingAvailable;
        },
        yearAndMake() {
            return `${this.vehicle.year} ${this.vehicle.make}`;
        },
        vehicleName() {
            return `${this.vehicle.year} ${this.vehicle.make} ${this.vehicle.model}`;
        },
        isConfiguredVehicle() {
            return this.inventoryVehicle === null;
        },
        isCertifiedDealer() {
            return _.get(this.dealer, "certified");
        }
    },

    watch: {
        canUnlockPricing(newVal, prevVal) {
            if (!prevVal && newVal) {
                this.loadPayment();
            }
        },
        dealer(newVal, prevVal) {
            if (!prevVal && newVal) {
                this.loadPayment();
            }
        },
        zipCode(newVal, prevVal) {
            if (!_.isNil(newVal) && newVal !== prevVal) {
                this.loadPayment();
            }
        }
    },

    created() {
        this.loadPayment();
    },

    mounted() {
        const isMobile = window.matchMedia(
            "only screen and (max-width: 990px)"
        );
        this.affixEnabled = !isMobile.matches;
        if (this.isUserSignedIn) {
            $('.nav-tabs a[data-target="#payment"]').tab('show')
        }
    },

    methods: {
        loadPayment() {
            if (_.isNil(this.zipCode)) {
                // Wait for zipcode.
                return;
            }

            if (!this.showRestrictedMake) {
                if (this.isConfiguredVehicle) {
                    this.loadConfiguredPayment();
                } else {
                    this.loadInventoryPayment();
                }
            }
        },
        loadConfiguredPayment() {
            if (!this.dealer || !this.canUnlockPricing) {
                const amp = _.get(
                    this.pricing,
                    "averageMarketPrice.value",
                    null
                );
                const msrp = _.get(this.pricing, "msrp", null);
                const price = _.isNil(amp) ? msrp : amp;

                dispatch("vehicleDetails/loadEstimatedPayment", {
                    styleId: this.vehicle.styleId,
                    price,
                    zipCode: this.zipCode
                });
            } else {
                dispatch("paymentMatrix/loadBest", {
                    certificateId: this.certificateId
                });
            }
        },
        loadInventoryPayment() {
            if (!this.dealer || !this.canUnlockPricing) {
                const isNew =
                    _.get(this.inventoryVehicle, "stockType") !== "used";
                const msrp = _.get(this.pricing, "msrp", null);
                const internetPrice = _.get(
                    this.pricing,
                    "dealerPrice.value",
                    null
                );
                const price = isNew ? msrp : internetPrice;

                dispatch("vehicleDetails/loadEstimatedPayment", {
                    id: this.inventoryVehicle.id,
                    price,
                    zipCode: this.zipCode
                });
            } else {
                dispatch("paymentMatrix/loadBest", {
                    certificateId: this.certificateId
                });
            }
        },
        openInsurance() {
            EventBus.$emit("insurance-modal-open");
        },
        openFinance() {
            EventBus.$emit("financing-modal-open");
        },
        openContactDealer() {
            dispatch("contactDealer/openModal", {
                certificateId: this.certificateId
            });
        },
        scheduleTestDrive() {
            dispatch("scheduleAppointment/openScheduleModal", {
                certificateId: this.certificateId
            });
        },
        gotoTradeIn() {
            if (this.certificateId !== null) {
                window.location = `/certificate/${this.certificateId}/tradein`;
            }
        },
        onViewInventoryClick() {
            window.location = `/dealer/${this.dealer.id}/inventory`;
        }
    }
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}

div[aria-expanded="false"] > div > div > .ion-chevron-up {
    transform: rotate(180deg);
    height: 20px;
}

.vue-affix {
    z-index: 20;
}

.pricing-card-wrapper {
    width: 100%;
    padding-left: 15px;

    .pricing-card {
        background-color: $white;
        border-radius: 2px;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);

        .card-header {
            background-color: #fff;
            padding: 18px 20px 0 20px;
            margin-bottom: 10px;
            cursor: pointer;
            display: flex;
            align-items: center;
            border-radius: 2px;
            border-bottom: none;

            .vehicle-title {
                padding-bottom: 15px;

                .year-and-make {
                    font-size: 14px;
                    color: #444;
                    line-height: 19px;
                }

                .model-and-trim {
                    color: #444444;
                    font-size: 28px;
                    line-height: 36px;
                }
            }
        }

        .card-vehicle-img {
            border-radius: 5px 5px 0 0 !important;
            overflow: hidden;

            .body-style-icon {
                font-size: 60px;
            }

            .desktop-vehicle-title {
                line-height: 18px;
                margin-top: 15px;

                .ymm {
                    font-size: 16px;
                }
            }
        }

        .pricing-card-tabs {
            .nav-tabs {
                border: none;
                padding: 5px 0;
                width: 90%;
                justify-content: center;

                .nav-item {
                    border: none;
                    cursor: pointer;
                    margin-left: 5px;
                    margin-right: 5px;

                    .nav-link {
                        font-family: $helvetica-55-Roman;
                        font-size: px2em(12);
                        font-weight: normal;
                        line-height: 4px;
                        text-align: center;
                        color: $primary-blue;
                        height: 26px;
                        border-radius: 26px;
                        padding: 0.7rem 1rem;

                        &.active {
                            background-color: $primary-blue;
                            font-family: $helvetica-75-bold;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}

.restricted-make-help-link {
    text-align: center;

    > i {
        font-size: 16px;
        line-height: 16px;
        color: $muted-gray;
    }

    > a {
        color: $muted-dark-gray;
        font-style: italic;
        text-decoration: underline;
        margin-left: 5px;
        font-size: 12px;
        line-height: 16px;

        &:hover {
            text-decoration: none;
        }
    }
}

.contact-us-block {
    width: 100%;

    .contact-us {
        .title {
            color: $primary-blue;
            font-size: px2em(14);
            line-height: 18px;
            font-weight: bold;
        }

        .sub-title {
            color: $dark-gray;
            font-size: 12px;
            line-height: 15px;
        }
    }
}

.cta-block {
    width: 100%;

    .cta {
        height: 70px;
        border: none;
        border-radius: 0;
        background-color: $white;

        a {
            font-size: 12px;
        }
    }

    .btn {
        flex: 1;
        font-size: px2rem(12);
        font-weight: bold;

        &__left {
            margin-right: 5px;
        }

        &__right {
            margin-left: 5px;
        }

        &:hover {
            background-color: $muted-gray;
        }
    }
}

@include media-breakpoint-up(lg) {
    .pricing-card-wrapper {
        width: 340px;
        position: relative;
        top: -175px;
        height: auto;
        order: 1;
        z-index: 20;

        .pricing-card {
            background-color: $white;
            border: 1px solid $muted-gray;
            border-radius: 8px !important;
            box-shadow: 0 4px 5px 0 $light-gray;
        }
    }
}
</style>
