var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.vehicleGallery
    ? _c(
        "flickity",
        {
          ref: "flickity",
          attrs: { id: "vehicleImageSlider", options: _vm.flickityOptions }
        },
        [
          _vm.vehicleGallery.length > 0
            ? _vm._l(_vm.vehicleGallery, function(image) {
                return _c(
                  "div",
                  { key: image, staticClass: "carousel-cell" },
                  [
                    _c("vehicle-image", {
                      attrs: { "image-url": image, alt: _vm.vehicleFullName }
                    })
                  ],
                  1
                )
              })
            : (_vm.vehicleGallery.length =
                0 && _vm.stockImages != null && _vm.stockImages.length > 0)
            ? _vm._l(_vm.stockImages, function(image) {
                return _c(
                  "div",
                  { key: image, staticClass: "carousel-cell" },
                  [
                    _c("vehicle-image", {
                      attrs: { "image-url": image, alt: _vm.vehicleFullName }
                    })
                  ],
                  1
                )
              })
            : (_vm.vehicleGallery.length =
                0 && (_vm.stockImages === null || _vm.stockImages.length === 0))
            ? _c("div", { staticClass: "carousel-cell" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "missing-vehicle-image d-flex justify-content-center"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "d-flex justify-content-center flex-column"
                      },
                      [
                        _c("body-style-icon", { attrs: { id: _vm.bodyStyle } }),
                        _c("br"),
                        _vm._v(" "),
                        _c("p", { staticClass: "mt-3 text-center" }, [
                          _vm._v(
                            "\n                    Image Coming Soon\n                "
                          )
                        ])
                      ],
                      1
                    )
                  ]
                )
              ])
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }